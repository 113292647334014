/*     brand Colors              */
.hamburger {
  padding: 0px 5px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }
    .hamburger:hover .hamburger-inner, .hamburger:hover .hamburger-inner::before, .hamburger:hover .hamburger-inner::after, .hamburger:hover .hamburger-inner.hamburger-text {
      background-color: #b6163d; }
  .hamburger.is-active:hover {
    opacity: 1; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: #b6163d; }

.hamburger-box {
  width: 20px;
  height: 13px;
  display: inline-block;
  position: relative;
  align-self: center; }

.hamburger-text {
  height: 100%;
  line-height: 100%;
  font-size: 14px;
  padding-top: 2px; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 20px;
    height: 3px;
    background-color: #193058;
    border-radius: 2px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: '';
    display: block; }
  .hamburger-inner::before {
    top: -5px; }
  .hamburger-inner::after {
    bottom: -5px; }

/*
     * Elastic
     */
.hamburger--elastic .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 5px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 10px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 5px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

h1 > a,
h2 > a,
h3 > a.text-black {
  color: #3C4858; }

a:hover {
  color: #b6163d; }

.bg-info {
  background-color: #fafafa !important; }

.nav-link:hover .hamburger-inner, .nav-link:hover .hamburger-inner::before, .nav-link:hover .hamburger-inner::after, .nav-link:hover .hamburger-inner.hamburger-text {
  background-color: #b6163d; }

input::placeholder {
  line-height: revert; }

.form-check,
label {
  color: #343a40c7; }

.wrapper {
  height: 100%; }

.breadcrumb {
  background-color: transparent; }

.form-check,
label {
  color: #343a40c7; }
